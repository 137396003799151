import React, { FC, ReactNode } from "react";
import { DEMO_STAY_LISTINGS, DEMO_TOURS_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";

//importacion de imagenes
import foto11 from "images/tours/t-foto11.jpeg";
import foto12 from "images/tours/t-foto12.jpeg";
import foto13 from "images/tours/t-foto13.jpeg";
import foto14 from "images/tours/t-foto14.jpeg";
import foto15 from "images/tours/t-foto15.jpeg";

import foto16 from "images/tours/t-foto16.jpeg";
import foto17 from "images/tours/t-foto17.jpeg";
import foto18 from "images/tours/t-foto18.jpeg";
import foto19 from "images/tours/t-foto19.jpeg";
import foto20 from "images/tours/t-foto20.jpeg";

const pictureArray1 = [foto11, foto12, foto13, foto14, foto15];
const pictureArray2 = [foto16, foto17, foto18, foto19, foto20];

// OTHER DEMO WILL PASS PROPS
var DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 2);
DEMO_DATA[0].galleryImgs = pictureArray1;
DEMO_DATA[1].galleryImgs = pictureArray2;

const TOURS_DATA: StayDataType[] = DEMO_TOURS_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Nuestros Tours",
  subHeading = "Viaja plancenteremante con nosotros a:",
  headingIsCenter,
  tabs = ["Colca"],
}) => {
  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.id} data={stay} />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"Colca"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 ${gridClass}`}
      >
        {DEMO_DATA.map((stay) => renderCard(stay))}
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
